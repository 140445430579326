import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "supported-vendors"
    }}>{`Supported Vendors`}</h1>
    <hr></hr>
    <ul>
      <li parentName="ul">{`Axis 360 `}</li>
      <li parentName="ul">{`BorrowBox`}</li>
      <li parentName="ul">{`cloudLibrary`}</li>
      <li parentName="ul">{`Hoopla`}</li>
      <li parentName="ul">{`OverDrive/Libby`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      